import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";
import VueForm, { fieldProps, vueUtils } from "@lljj/vue-json-schema-form";
// 圖片上傳組件
import TemplateUploadImgFile from "../components/templateUploadImgFile.vue";
// 影片上傳組件
import TemplateUploadVideoFile from "../components/templateUploadVideoFile.vue";

import SideBar from "@/views/templateSetting/components/SideBar.vue";

import { isEmpty } from "@/service/anyService";

export default {
    name: "templateIndexSection1",
    components: {
        TemplateUploadImgFile,
        TemplateUploadVideoFile,
        SideBar,
        VueForm,
    },
    props: {},
    emits: [],
    setup(props, { emit }) {
        const photoSizes = ref({
            width: [350],
            height: [400],
        });

        const templateForm = ref({
            templateSelect: {
                style: "tmp1",
            },
        });

        async function onSubmit(getFn, getForm) {
            try {
                await getFn().validate();
                console.log("form =>", getForm);
            } catch (err) {
                console.log("validate err =>", err);
            }
        }

        const templateSchema = ref({
            type: "object",
            properties: {
                templateSelect: {
                    title: "樣板選擇",
                    type: "object",
                    properties: {
                        style: {
                            title: "當前選擇樣板",
                            type: "string",
                            enum: ["tmp1", "tmp2"],
                            enumNames: ["樣板1", "樣板2"],
                            "ui:width": "40%",
                        },
                    },
                },
            },
        });
        const datasForm = ref({
            dataList: [
                {
                    title: "test title",
                    description: "test description",
                    dataList: [
                        {
                            title: "讓生活變得多采多姿",
                            description:
                                "眾多人氣正妹等你邀請，一起吃喝玩樂，探索城市，發現精彩時刻，享受人生。",
                            imgUpload: JSON.stringify({
                                imgUrl: "https://firebasestorage.googleapis.com/v0/b/timpicturespace.appspot.com/o/overflow-01.png?alt=media&token=7ed8d6b1-24f8-4cb9-8670-c3f3e911c1ad",
                                sizes: photoSizes.value,
                            }),
                        },
                        {
                            title: "簡單方便",
                            description:
                                "預約速度快，輕鬆動動手指就能約到人，節省時間又省力。",
                        },
                        {
                            title: "品質保證，放心玩",
                            description:
                                "資訊詳細，真實評價，運用先進、人性化的模式，為你打造安心的約妹體驗。",
                        },
                        {
                            title: "完善的支援",
                            description:
                                "透過我們應用程式的支持，預訂後將會收到訂單明細、活動詳情、所有必要資訊及協助。",
                        },
                    ],
                },
            ],
        });

        const datasSchema = ref({
            title: "開立訂單標題",
            type: "object",
            required: ["dataList"],
            properties: {
                dataList: {
                    type: "array",
                    items: {
                        type: "object",
                        required: [
                            "title",
                            "description",
                            "mainImg",
                            "dataList",
                        ],
                        properties: {
                            title: {
                                title: "標題",
                                type: "string",
                                "err:required": "請輸入標題",
                            },
                            description: {
                                title: "描述",
                                type: "string",
                                "err:required": "請輸入描述",
                            },
                            mainImg: {
                                title: "主要圖片",
                                type: "string",
                                "ui:widget": TemplateUploadImgFile,
                                default: JSON.stringify({
                                    imgUrl: null,
                                    sizes: photoSizes.value,
                                }),
                            },
                            dataList: {
                                title: "服務項目介紹",
                                type: "array",
                                minItems: 4,
                                maxItems: 4,
                                items: {
                                    type: "object",
                                    required: [
                                        "imgUpload",
                                        "title",
                                        "description",
                                    ],
                                    properties: {
                                        title: {
                                            title: "服務標題",
                                            type: "string",
                                            "err:required": "請輸入服務標題",
                                        },
                                        description: {
                                            title: "服務描述",
                                            type: "string",
                                            "err:required": "請輸入服務描述",
                                        },
                                        imgUpload: {
                                            title: "上傳 icon圖",
                                            type: "string",
                                            "ui:widget": TemplateUploadImgFile,
                                            "err:required": "請輸入圖片網址",
                                            default: JSON.stringify({
                                                imgUrl: null,
                                                sizes: photoSizes.value,
                                            }),
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        });
        const dataUiSchema = ref({});

        const errorSchema = ref({
            data: {
                dataList: {
                    items: {
                        imgUpload: {
                            "err:required": "請輸入圖片網址或上傳有效的圖片",
                        },
                    },
                },
            },
        });

        const customRule = ref(({ field, value, rootFormData, callback }) => {
            const rules = [
                {
                    rule: /dataList\.\d+\.mainImg/,
                    validator(value, rootFormData) {
                        const jsonDataParse = JSON.parse(value);
                        if (jsonDataParse.imgUrl === null)
                            return "請上傳主要圖片";
                    },
                },
                {
                    rule: /dataList\.\d+\.dataList\.\d+\.imgUpload/,
                    validator(value, rootFormData) {
                        const jsonDataParse = JSON.parse(value);
                        if (jsonDataParse.imgUrl === null) {
                            return "請上傳圖片";
                        }
                    },
                },
            ];

            for (const ruleItem of rules) {
                // String | Regx
                if (
                    (String(ruleItem.rule) === ruleItem.rule &&
                        ruleItem.rule === field) ||
                    (Object.prototype.toString.call(ruleItem.rule) ===
                        "[object RegExp]" &&
                        ruleItem.rule.test(field))
                ) {
                    const error = ruleItem.validator(value, rootFormData);
                    // 停止檢查
                    if (error) return callback(error);
                }
            }
            return callback();
        });

        const jsonData = ref({});
        function addClass() {}
        function handleSubmit(formData) {
            jsonData.value = formData;
        }

        return {
            addClass,
            templateForm,
            templateSchema,
            datasForm,
            datasSchema,
            dataUiSchema,
            errorSchema,
            onSubmit,
            customRule,
        };
    },
    render() {
        const scopedSlots = {
            default: (data) => (
                <div>
                    <pre
                        style={{
                            backgroundColor: "#eee",
                            overflowX: "scroll",
                            width: "800px",
                        }}
                    >
                        {JSON.stringify(data.formData, null, 4)}
                    </pre>
                    <p>
                        <el-button
                            type="primary"
                            onClick={() =>
                                this.onSubmit(data.formRefFn, data.formData)
                            }
                        >
                            Submit
                        </el-button>
                    </p>
                </div>
            ),
        };
        return (
            <div class="flex mx-5 my-5">
                <SideBar
                    btnText={"+ 新增類別"}
                    clickBtn={() => this.addClass()}
                />
                <div class="pb-5 flex-1">
                    <div class="shadow-lg bg-white rounded-lg ml-3 pb-5 relative p-5">
                        <VueForm
                            vModel={this.templateForm}
                            class="text-red-500"
                            schema={this.templateSchema}
                            scopedSlots={scopedSlots}
                        ></VueForm>
                        <VueForm
                            vModel={this.datasForm}
                            class="text-red-500"
                            schema={this.datasSchema}
                            ui-schema={this.dataUiSchema}
                            error-schema={this.errorSchema}
                            custom-rule={this.customRule}
                            scopedSlots={scopedSlots}
                        ></VueForm>
                    </div>
                </div>
            </div>
        );
    },
};
